import React, {useEffect, useState} from 'react';

import CoverPhotoCamera from '../../../assets/svg/CoverPhotoCamera.svg'
import Globe from '../../../assets/svg/Organization/Globe.svg';
import Tag from '../../../assets/svg/Organization/Tag.svg';
import LocationArrow from '../../../assets/svg/Organization/LocationArrow.svg';
import Clock from '../../../assets/svg/Organization/Clock.svg';
import moment from "moment";
import Posts from '../posts';
import {getAllPrograms} from '../../../utils/apiUrlsSetup';
import ProgramsComp from '../../program/components/programs';
import LoggedInLayout from "../../../component/layout/LoggedInLayout.tsx";
import {Link} from "react-router-dom";

const OrganizationWeb = (props) => {
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const [programs, setPrograms] = useState([])
  const [limit, setLimit] = useState(0)
  const [programCount, setProgramCount] = useState(0)
  const [organisationId, setOrganisationId] = useState(null)

  const getAllMemebersIdWithCheckAdmin = (members) => {
    let memebersId = members.filter(v => v.userId === userDetails.id);
    return !!(memebersId.length && memebersId[0].isAdmin);
  }

  const checkUserStatus = (members) => {
    let memebersId = members.filter(v => v.userId === userDetails.id);
    if (memebersId.length) {
      return memebersId[0].status;
    } else {
      return "";
    }
  }

  const checkIsUserMember = (members) => {
    let memebersId = members.filter(v => v.userId === userDetails.id);
    return !memebersId.length;
  }

  const checkIsUserMemberPost = (members) => {
    let memebersId = members.filter(v => v.status === "accepted").filter(v => v.userId === userDetails.id);
    return !!memebersId.length;
  }

  const checkIsUserAdmin = (members) => {
    let memebersId = members.filter(v => v.userId === userDetails.id);
    return !!(memebersId.length && memebersId[0].isAdmin);
  }

  const findAdminDetails = (members) => {
    let memebersId = members.filter(v => v.userId === userDetails.id);
    if (memebersId[0].isAdmin) {
      return memebersId[0];
    } else {
      return {};
    }
  }

  const findNonAdmins = (members) => {
    return members.filter(v => !v.isAdmin);
  }

  const findAdmins = (members) => {
    return members.filter(v => v.isAdmin);
  }

  useEffect(() => {
    if (props.orgId.length) {
      setOrganisationId(props.orgId[0].id)
    }
  }, [props.orgId])

  const getOrgPrograms = async () => {
    const response = await getAllPrograms(limit + 5, null, organisationId)
    if (response && response.status === 200) {
      setLimit(limit + 5)
      setPrograms(response.data.data.programArray)
      setProgramCount(response.data.data.count)
    }
  }

  const _orgName = props.orgId.length ? props.orgId[0].name : "New Organisation"

  return (
    <LoggedInLayout title={"Communities"}>
      <div>
        {props.showOrganisation && (
          <div className='d-flex flex-column gap-3'>

            <div className={"d-flex bg-white rounded-4 py-2 px-3 align-items-center gap-2 tw-cursor-default"}>

              {/* Avatar */}
              <Link to={"/experiences"} className={"tw-cursor-pointer tw-flex-shrink-0"}>
                <i className="bi bi-arrow-left"/>
              </Link>

              {/* Create post bar */}
              <div
                className={"tw-transition-all tw-text-lg tw-cursor-default montserrat fw-semibold tw-w-full"}>
                {_orgName}
              </div>
            </div>

            <img
              height={`${props.orgId.length && props.orgId[0].image ? "100%" : "50px"}`}
              src={props.orgId.length && props.orgId[0].image ? props.orgId[0].image : CoverPhotoCamera}
              className={"tw-max-h-[300px] tw-object-contain tw-object-center"}
              alt=''/>

            <div>
              <p
                className='new_org_heading new_org_heading_new mb-2'>{_orgName}</p>
              <p
                className='new_org_head_dtls'>{props.orgId.length ? props.orgId[0].privacy.charAt(0).toUpperCase() + props.orgId[0].privacy.slice(1) + " Community" : "Public/Private"} &nbsp; &bull; &nbsp; {props.orgId.length ? `${props.orgId[0].members.filter(v => v.status === "accepted").length} Member(s)` : "Member(s)"}</p>
            </div>

            <div className="row g-3">
              <div
                className="col-12 col-lg-6 d-flex align-items-end gap-3 order-lg-1 order-2 overflow-auto">
                {props.orgId.length && props.orgId[0].status === "approved" && checkIsUserMemberPost(props.orgId[0].members) &&
                  (
                    <div className={"text-center"}>
                      <button className={`p-1 px-4 new_org_header_btns`}
                              onClick={() => props.setActiveTab("post")}>Posts
                      </button>
                      <div
                        className={`active_bar ${props.activeTab === "post" ? "" : "invisible"}`}/>
                    </div>
                  )
                }

                <div className={"text-center"}>
                  <button className={`p-1 px-4 new_org_header_btns`}
                          onClick={() => props.setActiveTab("about")}>About
                  </button>
                  <div className={`active_bar ${props.activeTab === "about" ? "" : "invisible"}`}/>
                </div>

                {props.orgId.length &&
                  (
                    <div className={"text-center"}>
                      <button className={`p-1 px-4 new_org_header_btns`}
                              onClick={() => props.setActiveTab("member")}>Member(s)
                      </button>
                      <div
                        className={`active_bar ${props.activeTab === "member" ? "" : "invisible"}`}/>
                    </div>
                  )
                }

                {props.orgId.length &&
                  (
                    <div className={"text-center"}>
                      <button className={`p-1 px-4 new_org_header_btns`}
                              onClick={() => {
                                getOrgPrograms();
                                props.setActiveTab("program")
                              }}>Programme(s)
                      </button>
                      <div
                        className={`active_bar ${props.activeTab === "program" ? "" : "invisible"}`}/>
                    </div>
                  )
                }
              </div>

              <div className={"col-12 col-lg-6 d-flex gap-3 order-1 order-lg-2 justify-content-end"}>
                {!props.orgId.length && (
                  <button className='create_new_group' onClick={() => props.setOpenModal(true)}>
                    Create New Community
                  </button>
                )}
                {props.orgId.length && getAllMemebersIdWithCheckAdmin(props.orgId[0].members) && (
                  <button className='create_new_group' onClick={() => props.setAddFriendModal(true)}>
                    Add Member
                  </button>
                )}
                {props.orgId.length && !getAllMemebersIdWithCheckAdmin(props.orgId[0].members) && checkIsUserMember(props.orgId[0].members) && (
                  <button className='create_new_group' onClick={() => props.joinGroupFunc(true)}>
                    Join Community
                  </button>
                )}

                {props.orgId.length && !getAllMemebersIdWithCheckAdmin(props.orgId[0].members) && !checkIsUserMember(props.orgId[0].members) && checkUserStatus(props.orgId[0].members) === "invited" &&
                  <>
                    <button className='create_new_group'
                            onClick={() => props.acceptReqByMemeber()}>Accept
                      Invite
                    </button>
                    <button className='create_new_group reject_invite'
                            onClick={() => props.rejectReqByMemeber()}>Reject
                      Invite
                    </button>
                  </>
                }

                {props.orgId.length && !getAllMemebersIdWithCheckAdmin(props.orgId[0].members) && !checkIsUserMember(props.orgId[0].members) && checkUserStatus(props.orgId[0].members) === "pending" &&
                  <>
                    <button className='create_new_group'>Requested</button>
                    <button className='create_new_group reject_invite'
                            onClick={() => props.cancelReq()}>
                      Cancel
                    </button>
                  </>
                }

                {props.orgId.length && !getAllMemebersIdWithCheckAdmin(props.orgId[0].members) && !checkIsUserMember(props.orgId[0].members) && checkUserStatus(props.orgId[0].members) === "accepted" &&
                  <>
                    <button className='create_new_group reject_invite'
                            onClick={() => props.cancelReq("leave")}>
                      Leave
                    </button>
                  </>
                }
              </div>
            </div>

            {props.activeTab === "about" &&
              <div className={"row g-lg-4 g-3"}>
                <div className="col-12 col-md-6">
                  <div className="card tw-rounded-3xl border-0 shadow-sm">
                    <div className="card-body d-flex flex-column gap-3">
                      <p className='mb-0 abt_org_heading'>About this community</p>

                      <p className='mb-0 abt_info tw-text-sm'>
                        {props.orgId.length ? props.orgId[0].description : "Include 1-2 sentences about your organisation here."}
                      </p>

                      {[
                        {
                          img: Globe,
                          upperText: props.orgId.length ? props.orgId[0].privacy.charAt(0).toUpperCase() + props.orgId[0].privacy.slice(1) + " Community" : "Public Group",
                          lowerText: "Anyone can see who’s in the community and what they post."
                        },
                        {
                          img: LocationArrow,
                          upperText: "Location",
                          lowerText: "Singapore (Limited to Singapore for Version 1.0)"
                        },
                        {
                          img: Tag,
                          upperText: "Community Code",
                          lowerText: props.orgId.length && props.orgId[0].inviteCode ? props.orgId[0].inviteCode : "---"
                        },
                        {
                          img: Clock,
                          upperText: "History",
                          lowerText: "Community created on " + (props.orgId.length ? moment(props.orgId[0].createdAt).format("DD MMM YYYY") : "12 Jan 2022")
                        }
                      ].map((e, i) => {
                        return (
                          <div className="d-flex align-items-center gap-3">
                            <img src={e.img} className={"tw-w-[20px] tw-h-[20px]"} alt=""/>
                            <div className={"lh-1 d-flex flex-column"}>
                              <div className='abt_topics_span tw-text-sm'>
                                {e.upperText}
                              </div>
                              <small className={"text-secondary"}>{e.lowerText}</small>
                            </div>
                          </div>
                        )
                      })}

                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="card tw-rounded-3xl border-0 shadow-sm">
                    <div className="card-body d-flex flex-column gap-3">
                      <p className='mb-0 abt_org_heading text-secondary'>
                        {props.orgId.length ? `${props.orgId[0].members.filter(v => v.status === "accepted").length} Member(s)` : "0 Member(s)"}
                      </p>


                      {props.orgId.length && findNonAdmins(props.orgId[0].members).filter(v => v.status === "accepted").length &&
                        <>
                          <div className="d-flex flex-wrap">
                            {findNonAdmins(props.orgId[0].members).filter(v => v.status === "accepted").map((v, i) => {
                              return <>
                                <div className='profile_pic me-3'>
                                  <img
                                    src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                                    alt='' style={{borderRadius: '50%'}}/>
                                </div>
                              </>
                            })}
                          </div>
                          <div className="flex-wrap d-flex align-items-center ">
                            {findNonAdmins(props.orgId[0].members).filter(v => v.status === "accepted").map((v, i) => {
                              return <>
                                <div key={i}>
                                  <p
                                    className='mb-0 admin_status'>{v.user.firstName}{findNonAdmins(props.orgId[0].members).length > 1 && ","}
                                    &nbsp;{i === findNonAdmins(props.orgId[0].members).length - 1}</p>
                                </div>
                              </>
                            })}
                            <p
                              className='mb-0 admin_status'>{props.orgId[0].members.length > 1 ? "are members." : "is a member."}</p>
                          </div>
                        </>
                      }


                      {props.orgId.length && !checkIsUserAdmin(props.orgId[0].members) && <>
                        <div className="d-flex">
                          {findAdmins(props.orgId[0].members).map((v, i) => {
                            return <>
                              <div className='profile_pic me-3'>
                                <img
                                  src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                                  alt='' style={{borderRadius: '50%'}}/>
                              </div>

                            </>
                          })}
                        </div>

                      </>}

                      {props.orgId.length && !checkIsUserAdmin(props.orgId[0].members) && <>

                        <div className="d-flex mb-4">
                          {findAdmins(props.orgId[0].members).map((v, i) => {
                            return <>
                              <p
                                className='mb-0 mt-2 admin_status'>{v.user.firstName} {i !== findAdmins(props.orgId[0].members).length - 1 && " , "} {i === findAdmins(props.orgId[0].members).length - 1 && findAdmins(props.orgId[0].members).length > 1 ? "are administrators." : "is an administrator."}</p>

                            </>
                          })}
                        </div>

                      </>}


                      {props.orgId.length && checkIsUserAdmin(props.orgId[0].members) && <>
                        <div className='profile_pic'>
                          <img
                            src={findAdminDetails(props.orgId[0].members).user.profilePic ? findAdminDetails(props.orgId[0].members).user.profilePic : "/static/png/Male.png"}
                            alt='' style={{borderRadius: '50%'}}/>
                        </div>
                        <p className='mb-0 mt-2 admin_status'>You are an admin.</p>
                      </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            }

            {props.activeTab === "member" && <>
              <div className="card card-body border-0 shadow-sm tw-rounded-3xl">

                <div className={"table-responsive"}>
                  <table className="table vertical-middle">
                    <thead>
                    <tr>
                      <th className={"fw-semibold tw-text-xs !tw-text-opacity-50 !tw-text-[#334d6e] montserrat "}>
                        Name
                      </th>
                      <th
                        className={"d-none d-lg-table-cell fw-semibold tw-text-xs !tw-text-opacity-50 !tw-text-[#334d6e] montserrat "}>
                        Role
                      </th>
                      <th
                        className={"d-none d-lg-table-cell fw-semibold tw-text-xs !tw-text-opacity-50 !tw-text-[#334d6e] montserrat "}>
                        Date joined
                      </th>

                      {props.orgId.length && getAllMemebersIdWithCheckAdmin(props.orgId[0].members) &&
                        <th
                          className={'d-none d-lg-table-cell fw-semibold tw-text-xs !tw-text-opacity-50 !tw-text-[#334d6e] montserrat text-center'}>
                          Action
                        </th>
                      }
                    </tr>
                    </thead>
                    <tbody>
                    {
                      props.orgId[0].members.filter(v => v.status !== "left").filter(v => v.status !== "removed").filter(v => checkIsUserAdmin(props.orgId[0].members) ? v : v.status === "accepted").map((v) => {
                        return (
                          <tr key={v.id} className={"tw-text-sm"}>
                            <td>
                              <div
                                className='d-flex align-items-center gap-2 py-1'>
                                <img
                                  className={"tw-object-cover rounded-circle tw-h-[30px] tw-w-[30px]"}
                                  src={v.user.profilePic ? v.user.profilePic : "/static/png/Male.png"}
                                  alt=''/>

                                <p
                                  className='admin_userlist_profile_name tw-text-sm'>{v.user.firstName} {v.user.lastName}</p>
                              </div>
                              <div className={"d-flex flex-column gap-1 d-lg-none"}>
                                <div className={"row tw-text-xs text-secondary w-100"}>
                                  <div className="col-3">Role</div>
                                  <div className="col-9">
                                    {v.isAdmin ? "Super Admin" : "User"}
                                  </div>
                                </div>
                                <div className={"row tw-text-xs text-secondary w-100"}>
                                  <div className="col-3">Date joined</div>
                                  <div className="col-9">
                                    {moment(v.createdAt).format("MM/DD/YYYY")}
                                  </div>
                                </div>
                                {props.orgId.length && getAllMemebersIdWithCheckAdmin(props.orgId[0].members) &&
                                  <div className={"my-1"}>
                                    {v.status === "invited" && !v.isAdmin &&
                                      <button
                                        className='invited_btn'>Invited</button>
                                    }
                                    {v.status === "pending" && !v.isAdmin &&
                                      <button className='invited_btn confirm_btn'
                                              onClick={() => props.confirmReq(v.userId)}>Confirm</button>
                                    }
                                    {v.status === "accepted" && !v.isAdmin &&
                                      <button className='invited_btn remove_btn'
                                              onClick={() => props.deleteReq(v.userId)}>Remove</button>
                                    }
                                  </div>
                                }
                              </div>
                            </td>

                            <td className={"d-none d-lg-table-cell !tw-text-opacity-50 !tw-text-[#334d6e]"}>
                              {v.isAdmin ? "Super Admin" : "User"}
                            </td>
                            <td className={"d-none d-lg-table-cell !tw-text-opacity-50 !tw-text-[#334d6e]"}>
                              {moment(v.createdAt).format("MM/DD/YYYY")}
                            </td>
                            {props.orgId.length && getAllMemebersIdWithCheckAdmin(props.orgId[0].members) &&
                              <td className={"d-none d-lg-table-cell text-center"}>
                                {v.status === "invited" && !v.isAdmin &&
                                  <button className='invited_btn'>Invited</button>
                                }
                                {v.status === "pending" && !v.isAdmin &&
                                  <button className='invited_btn confirm_btn'
                                          onClick={() => props.confirmReq(v.userId)}>Confirm</button>
                                }
                                {v.status === "accepted" && !v.isAdmin &&
                                  <button className='invited_btn remove_btn'
                                          onClick={() => props.deleteReq(v.userId)}>Remove</button>
                                }
                              </td>
                            }
                          </tr>
                        )
                      })
                    }
                    </tbody>
                  </table>
                </div>

              </div>
            </>}

            {props.activeTab === "post" && (
              <Posts
                setShowUserLikedList={props.setShowUserLikedList}
                orgHasDetails={props.orgId.length ? props.orgId : []}
                members={props.orgId.length ? props.orgId[0].members : []}
              />
            )}

            {props.activeTab === "program" ? (
              <div className="row m-0">
                <div className="col-9 p-0">
                  <ProgramsComp classes="col-4 ps-0 pe-4 mb-4"
                                getExperiences={getOrgPrograms} experiences={programs}
                                experienceCount={programCount}/>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </LoggedInLayout>
  )
}

export default OrganizationWeb;