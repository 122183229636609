import {createPostLike, deletePostLike} from "../../../../utils/apiUrlsSetup";
import {useZStore} from "../../../../utils/zStore.ts";
import React, {useEffect, useRef} from "react";

/**
 * @param {{
 *   commentId: number,
 *   reactorState: [
 *      {userId: string, type: string}[] | null,
 *      React.Dispatch<React.SetStateAction<{userId: string, type: string}[] | null>>,
 *      (boolean) => Promise<{userId: string, type: string}[]>
 *   ]
 * }} props
 * @constructor
 */
export default function CommentLikeButton(props) {
  const store = useZStore()
  const userDetails = store.userDetails;
  const outTimerRef = useRef()
  const inTimerRef = useRef()
  const touchTimeoutRef = useRef(null);
  const hasHeldRef = useRef(false);

  const commentReaction = props.reactorState[0]
  const mutateCommentReaction = props.reactorState[2]

  const sendReaction = async (type) => {
    store.setShowingReactionTopId(``)
    await createPostLike({commentId: props.commentId, type: type}).then(async () => {
      await mutateCommentReaction()
    })
  }

  // Handle touch start (start detecting tap or hold)
  const handleTouchStart = () => {
    hasHeldRef.current = false;  // Reset hold state
    touchTimeoutRef.current = setTimeout(() => {
      hasHeldRef.current = true; // Mark that it has been held
      store.setShowingReactionTopId(`comment-${props.commentId}`);
    }, 250);  // 1 second threshold for "Holded"
  };

  // Handle touch end (stop detecting and decide between tap and hold)
  const handleTouchEnd = (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()
    } catch {
    }

    // Clear the timeout if touch ends before 1 second
    clearTimeout(touchTimeoutRef.current);

    // If it was not held for more than 1 second, treat as "Tapped"
    if (!hasHeldRef.current) {
      onMainButtonClicked(e);
    }
  };

  const handleMouseEnter = (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()
    } catch {
    }

    if (outTimerRef.current) {
      clearTimeout(outTimerRef.current);
      outTimerRef.current = null;
    }

    inTimerRef.current = setTimeout(() => {
      store.setShowingReactionTopId(`comment-${props.commentId}`);
    }, 250);
  };

  const handleMouseLeave = (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()
    } catch {
    }

    if (inTimerRef.current) {
      clearTimeout(inTimerRef.current);
      inTimerRef.current = null;
    }

    outTimerRef.current = setTimeout(() => {
      store.setShowingReactionTopId(``)
    }, 400);
  };

  useEffect(() => {
    if (!commentReaction) {
      mutateCommentReaction()
    }
  }, [commentReaction, mutateCommentReaction]);

  // Cancel onMouseLeave if mouse re-enters
  useEffect(() => {
    return () => {
      if (inTimerRef.current) {
        clearTimeout(inTimerRef.current);
      }

      if (outTimerRef.current) {
        clearTimeout(outTimerRef.current);
      }
    };
  }, []);

  const onMainButtonClicked = async (e) => {
    try {
      e.preventDefault()
      e.stopPropagation()
    } catch {
    }
    store.setShowingReactionTopId(``);

    const existingReaction = (commentReaction ?? []).filter(e => {
      return e.userId === userDetails.id
    })
    if (existingReaction.length > 0) {
      await deletePostLike({commentId: props.commentId}).then(async () => {
        await mutateCommentReaction()
      })
      return;
    }

    await createPostLike({commentId: props.commentId}).then(async () => {
      await mutateCommentReaction()
    })
  }

  let currentReactionId = (commentReaction ?? []).find(e => e.userId === userDetails.id)?.type ?? "like"
  let currentReaction = currentReactionId[0].toUpperCase() + currentReactionId.slice(1)

  let color = "#ffd212"
  if (currentReactionId === "angry") {
    color = "#f16b1f"
  } else if (currentReactionId === "love") {
    color = "#f2506c"
  } else if (currentReactionId === "like") {
    color = "#54bfed"
  }

  return (
    <div className={"lg:tw-relative"}>
      {commentReaction && (
        <div
          onClick={onMainButtonClicked}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className={"tw-cursor-pointer tw-font-medium"}
          style={{
            color: commentReaction.map(e => e.userId).includes(userDetails.id) ? color : "#565656"
          }}
        >
          {currentReaction}
        </div>
      )}

      <div
        className={`position-absolute ${store.showingReactionTopId === `comment-${props.commentId}` ? '' : 'd-none'} tw-z-[998] bottom-100 text-dark bg-white shadow-sm border tw-px-1 rounded-pill d-flex reactionShowTopup`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {[
          "like",
          "love",
          "hug",
          "happy",
          "wow",
          "sad",
          "angry",
        ].map(e => {
          return (
            <div
              key={e}
              onClick={() => sendReaction(e)}
              className={"tw-cursor-pointer d-flex tw-w-[40px] tw-h-[40px] justify-content-center align-items-center text-primary rounded-circle reactionShowTopup"}>
              <img alt={e} src={`/static/r_${e}.png`}
                   className={"tw-w-[40px] tw-h-[40px] hover:tw-scale-125 tw-transition-all reactionShowTopup"}/>
            </div>
          )
        })}
      </div>

      <div className={"lg:tw-hidden tw-block"}>
        <div
          data-active={store.showingReactionTopId === `comment-${props.commentId}`}
          className={`position-absolute data-[active=true]:!tw-flex tw-hidden tw-z-[998] -tw-translate-y-full -tw-mt-5 tw-end-4 text-dark bg-white shadow-sm border tw-px-1 rounded-pill reactionShowTopup`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {[
            "like",
            "love",
            "hug",
            "happy",
            "wow",
            "sad",
            "angry",
          ].map(e => {
            return (
              <div
                key={e}
                onClick={() => sendReaction(e)}
                className={"tw-cursor-pointer d-flex tw-w-[40px] tw-h-[40px] justify-content-center align-items-center text-primary rounded-circle reactionShowTopup"}>
                <img alt={e} src={`/static/r_${e}.png`}
                     className={"tw-w-[40px] tw-h-[40px] hover:tw-scale-125 tw-transition-all reactionShowTopup"}/>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}