import {create} from 'zustand'
import {createJSONStorage, devtools, persist} from 'zustand/middleware'

type UserDetails = {
  userType: string
}

type zStoreType = {
  userDetails: UserDetails,
  setUserDetails: (args: UserDetails) => void,
  fullscreenImgSrc: string | null,
  setFullscreenImgSrc: (args: string | null) => void,
  showingReactionTopId: string,
  setShowingReactionTopId: (args: string) => void,
}

const useZStore = create<zStoreType>()(
  devtools(
    persist(
      (set) => ({
        userDetails: null,
        setUserDetails: (userDetails) => set(() => ({userDetails: userDetails})),
        fullscreenImgSrc: null,
        setFullscreenImgSrc: (fullscreenImgSrc: string | null) => set(() => ({fullscreenImgSrc})),
        showingReactionTopId: "",
        setShowingReactionTopId: (showingReactionTopId: string) => set(() => ({showingReactionTopId})),
      }),
      {
        name: 'wbc-zstand', // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
      },
    ),
  ),
)

export {useZStore};