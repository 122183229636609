import React, {useState} from "react";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const SignUpForm = (props) => {
    const [emailValidationMsg, setEmailValidationMsg] = useState(null)
    const [pwdValidationMsg, setPwdValidationMsg] = useState(null)
    const [uenValidationMsg, setUenValidationMsg] = useState(null)

    const validateForm = () => {
        setEmailValidationMsg(null)
        setPwdValidationMsg(null)
        setUenValidationMsg(null)
        var validated = true

        var result = props.ValidateEmail(props.signupData.email)
        if (result !== "Email is valid") {
            setEmailValidationMsg(result)
            validated = false
        }

        result = props.ValidateUen(props.signupData.uen)
        if (result !== "UEN is valid") {
            setUenValidationMsg(result)
            validated = false
        }

        result = props.ValidatePwd(props.signupData.setpassword)
        if (result !== "Password is valid") {
            setPwdValidationMsg(result)
            validated = false
        }

        if (!validated) {
            return
        }

        if (props.signupData.userType === 'wp') {
            props.setStep(3)
        } else {
            props.handleSubmit()
        }
    }

    return (
        <div className="d-flex gap-4 flex-column">
            <div className={"text-center"}>
                <p className="tw-text-3xl tw-text-[#54bfed] tw-font-semibold">Let’s get started!</p>
                <p className={"sm_blue_text"}>Register your account details here. You can fill up your profile
                    later.</p>
            </div>

            <hr className="m-0 p-0"/>

            <div className={"d-flex flex-column justify-content-center align-items-center"}>
                <label className="mb-2 tw-w-[600px] tw-max-w-[100%]">
                    First Name <sup className="text-danger">*</sup>
                </label>
                <input
                    className="input_filds signup_inputes tw-w-[600px] tw-max-w-[100%]"
                    type="text"
                    id="firstName"
                    name="firstName"
                    onChange={props.onChangeHandler}
                />
            </div>

            <div className={"d-flex flex-column justify-content-center align-items-center"}>
                <label className="mb-2 tw-w-[600px] tw-max-w-[100%]">
                    Last Name <sup className="text-danger">*</sup>
                </label>
                <input
                    className="input_filds signup_inputes tw-w-[600px] tw-max-w-[100%]"
                    type="text"
                    id="lastName"
                    name="lastName"
                    onChange={props.onChangeHandler}
                />
            </div>

            <div className={"d-flex flex-column justify-content-center align-items-center"}>
                <label className="mb-2 tw-w-[600px] tw-max-w-[100%]">
                    Your Business Email <sup className="text-danger">*</sup>
                </label>
                <input
                    className="input_filds signup_inputes tw-w-[600px] tw-max-w-[100%]"
                    type="email"
                    id="email"
                    name="email"
                    onChange={props.onChangeHandler}
                />
                <p className={"small tw-w-[600px] tw-max-w-[100%]"}>
                    ({props.signupData.userType === 'wp' ? "if you don't have Business email, please provide a Personal email address" : "please do not provide your personal email details"})
                </p>
                {emailValidationMsg ? (
                    <span className="validation">{emailValidationMsg}</span>
                ) : null}
            </div>

            <div className={"d-flex flex-column justify-content-center align-items-center"}>
                <label className="mb-2 tw-w-[600px] tw-max-w-[100%]">
                    Your Job Title <sup className="text-danger">*</sup>
                </label>
                <input
                    className="input_filds signup_inputes tw-w-[600px] tw-max-w-[100%]"
                    type="text"
                    id="jobTitle"
                    name="jobTitle"
                    onChange={props.onChangeHandler}
                />
            </div>

            <div className={"d-flex flex-column justify-content-center align-items-center"}>
                <label className="mb-2 tw-w-[600px] tw-max-w-[100%]">
                    Phone Number
                </label>
                <PhoneInput
                    value={props.signupData.mobile}
                    onChange={props.handleMobileNumber}
                    defaultCountry="SG"
                    className={"tw-w-[600px] tw-max-w-[100%]"}
                />
            </div>

            <div className={"d-flex flex-column justify-content-center align-items-center"}>
                <label className="mb-2 tw-w-[600px] tw-max-w-[100%]">
                    Your Organisation <sup className="text-danger">*</sup>
                </label>
                <input
                    className="input_filds signup_inputes tw-w-[600px] tw-max-w-[100%]"
                    type="text"
                    id="organisation"
                    name="organisation"
                    onChange={props.onChangeHandler}
                />
            </div>

            <div className={"d-flex flex-column justify-content-center align-items-center"}>
                <label className="tw-w-[600px] tw-max-w-[100%]">
                    Your Organisation UEN
                        <p className="small mb-2 tw-w-[600px] tw-max-w-[100%] lh-1">
                            (If your organisation is registered, you may fill in the UEN below.)
                        </p>
                </label>
                <p className="small mb-2 tw-w-[600px] tw-max-w-[100%]">
                    (UEN Search:
                    <a
                        target="_blank"
                        href="https://www.uen.gov.sg"
                        rel="noreferrer"
                        className={"text-decoration-underline mx-1"}
                    >
                        Click here
                    </a>
                    )
                </p>
                <input
                    className="input_filds signup_inputes tw-w-[600px] tw-max-w-[100%]"
                    type="text"
                    id="uen"
                    name="uen"
                    onChange={props.onChangeHandler}
                    maxLength={10}
                />
                {uenValidationMsg ? (
                    <span className="validation">{uenValidationMsg}</span>
                ) : null}
            </div>


            <div className={"d-flex flex-column justify-content-center align-items-center"}>
                <label className="tw-w-[600px] tw-max-w-[100%]">
                    Set Password <sup className="text-danger">*</sup>
                </label>
                <p className="small mb-2 tw-w-[600px] tw-max-w-[100%] lh-1">
                    (Your password should have at least 8 characters and must contain mix of upper & lower case letters,
                    numbers and symbols. No space is allowed.)
                </p>
                <input
                    className="input_filds signup_inputes tw-w-[600px] tw-max-w-[100%]"
                    type={props.ispwd ? "text" : "password"}
                    id="setpassword"
                    name="setpassword"
                    autoComplete={"new-password"}
                    onChange={props.onChangeHandler}
                />

                {pwdValidationMsg ? (
                    <span className="validation">{pwdValidationMsg}</span>
                ) : null}
            </div>

            <div className={"d-flex gap-3 justify-content-end align-items-center"}>
                <button className="next_btn active_btn" onClick={() => {props.setStep(1)}}>
                    Back
                </button>

                {
                    props.signupData.userType === 'wp' ? (
                        <button className={`next_btn ${props.btndisable ? 'deactive-btn ' : 'active_btn'}`}
                                disabled={props.btndisable} onClick={validateForm}>Next</button>
                    ) : (
                        <button
                            className={`create_ac_btn ${
                                props.btndisable ? "deactive-btn " : "active_btn"
                            }`}
                            disabled={props.btndisable}
                            onClick={validateForm}
                        >
                            Create Account
                        </button>
                    )
                }
            </div>
        </div>
    );
};

export default SignUpForm;
