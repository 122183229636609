import {useZStore} from "../utils/zStore.ts";

export default function FullScreenImage() {
  const store = useZStore()

  if (store.fullscreenImgSrc === null) {
    return <></>
  }

  return (
    <>
      {/* Overlay */}
      <div className={"position-fixed top-0 start-0 w-100 h-100 tw-bg-black tw-bg-opacity-80 tw-z-[999]"} onClick={() => {
        store.setFullscreenImgSrc(null)
      }}/>
      <img alt={store.fullscreenImgSrc} src={store.fullscreenImgSrc} className={"tw-max-w-[90vw] tw-z-[1000] tw-max-h-[90vh] position-fixed top-50 start-50 translate-middle"} />
    </>
  )
}