import {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
// @ts-expect-error still combined
import LoggedInLayout from "../../component/layout/LoggedInLayout.tsx"

import "../../css/timeExchange.css";
import {getBids, getOffers} from '../../utils/apiUrlsSetup'
// @ts-expect-error still combination
import {getTimeExPoints} from '../../utils/apiUrlsSetupV2.ts'
// @ts-expect-error still combination
import Points from './component/Points.tsx'
import PostText from "../../component/PostText";
import {useZStore} from "../../utils/zStore.ts";
import {BidObject, GetTimeExPointsResponse, OfferObject, User} from "../../utils/ApiUrlsSetupTypes";

function Exchanges() {
  const [point, setPoints] = useState<GetTimeExPointsResponse>();
  const [offers, setOffers] = useState<OfferObject[]>([])
  const [bids, setBids] = useState<BidObject[]>([])
  const [search, setSearch] = useState("");

  const [activeTab, setActiveTab] = useState("All")
  const [isShowGifts, setIsShowGifts] = useState(true)
  const [isShowRequests, setIsShowRequests] = useState(true)

  const store = useZStore()

  useEffect(() => {
    getTimeExPoints().then(responseData => {
      if (responseData.status === 200) {
        setPoints(responseData.data.data);
      }
    })

    getOffers(null, 100).then(response => {
      if (response.status === 200) {
        let offerArray = response.data.data.offerArray;

        // TODO: Move this to backend
        offerArray = offerArray.filter(e => {
          if (e.userId === store.userDetails.id) return true;
          if (!e.unpublishedAt) return true;

          if (e.isClosed) return false;
          return new Date(e.unpublishedAt) > new Date()
        })

        setOffers(offerArray)
      }
    })

    getBids({userId: store.userDetails.id}, 100).then(response => {
      setBids(response.data.data.oneToOneSessionArray.filter(v => v.offerId))
    })
  }, []);

  return (
    <LoggedInLayout title={"Giving"}>
      <div className="container">
        <div className="d-flex flex-column gap-3">
          <div>
            {/* Title */}
            <div className="d-flex justify-content-between">
              <h1 className={"tw-text-[#410db7] montserrat fw-bold tw-text-2xl m-0"}>Giving</h1>
              <Points wbPoints={point?.wbPoints} wbTime={point?.wbTime}/>
            </div>

            <p className={"tw-text-[#410db7] tw-font-medium tw-leading-5 mt-2"}>
              Space to exchange mutual acts of care, kindness and gratitude through well-being Gifts.
            </p>
          </div>

          <div className={"d-flex"}>
            <div className={"tw-p-1 tw-bg-[#54bfed]"}>

            </div>
            <div className={"tw-h-full tw-w-full tw-px-2 tw-py-4 tw-flex tw-flex-col tw-bg-[#f0fbff]"}>
              <p className={"tw-leading-5 tw-font-semibold mb-2"}>
                A well-being gift is an offer of your time to listen to, mentor, or carry out an act of kindness for a
                fellow Network member.
              </p>

              <ul className={"tw-leading-5"}>
                <li className={"mb-2"}>
                  <i className="bi bi-dot d-none d-md-inline"/> Each time you show care or give kindly, your Well-being
                  Time Balance
                  increases by 1 Hour.
                </li>
                <li>
                  <i className="bi bi-dot d-none d-md-inline"/> That hour you gain may then be exchanged for another
                  Well-being Gift of your choice.
                </li>
              </ul>
            </div>
          </div>

          <div className="tw-flex tw-gap-2">
            {["All", "My Gifts and Requests", "Active", "History"].map(e => {
              return (
                <button
                  key={e}
                  type={"button"}
                  data-active={activeTab === e}
                  onClick={() => {
                    setActiveTab(e)
                  }}
                  className={"btn-sm tw-font-medium montserrat tw-text-[#410db7] data-[active=true]:!tw-text-white hover:tw-text-white btn tw-border tw-border-[#410db7] hover:tw-bg-[#410db7] data-[active=true]:!tw-bg-[#410db7]"}>
                  {e}
                </button>
              )
            })}
          </div>

          <div className={"tw-flex tw-gap-4"}>
            <div className="input-group shadow-sm">
              <input
                type="text"
                onChangeCapture={(e) => setSearch((e.target as HTMLInputElement).value)}
                className="form-control all_exp_page_lg_input border-0"
                placeholder="Search keyword"
              />
              <span
                className="tw-cursor-pointer input-group-text bg-white border-0">
                <i className="bi bi-search"/>
              </span>
            </div>

            <Link to={`/time-exchange/exchange/create-request`}
                  className='btn !tw-bg-blue-500 !tw-text-white tw-flex tw-text-nowrap tw-items-center tw-justify-center'>
              <i className="bi bi-plus-circle-fill me-2"></i> Request
            </Link>

            <Link to={`/time-exchange/exchange/create-offer`}
                  className='btn !tw-bg-blue-500 !tw-text-white tw-flex tw-text-nowrap tw-items-center tw-justify-center'>
              <i className="bi bi-plus-circle-fill me-2"></i> Gift
            </Link>
          </div>

          <div className='d-flex justify-content-between tw-items-center tw-gap-3 tw-flex-wrap'>
            <div className={"flex-shrink-0 tw-text-nowrap"}>
              {
                ["All", "My Gifts and Requests"].includes(activeTab) && (
                  <p className='mb-0 all_exe_top_results'>
                    {
                      offers
                        .filter((curr) => {
                          if (activeTab === "All") return true;
                          if (activeTab === "My Gifts and Requests") return curr.userId === store.userDetails.id;
                          return false;
                        })
                        .filter((curr) => {
                          if (!isShowRequests && curr.offerType === "request") return false;
                          return !(!isShowGifts && curr.offerType === "gift");
                        })
                        .filter((curr) => {
                          if (!search) return true;
                          return `${curr.title}${curr.user.firstName}${curr.user.lastName}`.toLowerCase().indexOf(search.toLowerCase()) >= 0
                        })
                        .filter(offer => !offer.isClosed)
                        .length
                    } Results
                  </p>
                )
              }

              {
                ["History", "Active"].includes(activeTab) && (
                  <p className='mb-0 all_exe_top_results'>
                    {
                      bids
                        .filter((curr) => {
                          if (!isShowRequests && curr.offer.offerType === "request") return false;
                          return !(!isShowGifts && curr.offer.offerType === "gift");
                        })
                        .filter(e => {
                          if (activeTab === 'Active') {
                            if (e.status === 'rejected') return false;
                            if (new Date() > new Date(e.endDate)) return false;
                          }
                          return true;
                        })
                        .filter((curr) => {
                            if (!search) return true;
                            return `${curr.offer.title}${curr.offer.user.firstName}${curr.offer.user.lastName}`
                              .toLowerCase()
                              .indexOf(search.toLowerCase()) >= 0;
                          }
                        )
                        .length
                    } Results
                  </p>
                )
              }
            </div>

            <div className={"tw-flex tw-gap-3"}>
              <button type={"button"} onClick={() => setIsShowGifts(prev => !prev)}
                      className="tw-cursor-pointer tw-px-4 tw-pb-1 tw-pt-0.5 tw-text-blue-500 tw-bg-white tw-border tw-border-blue-500 tw-rounded-3xl tw-flex tw-items-center tw-gap-2">
                {isShowGifts ?
                  <i className="bi bi-eye-fill"></i>
                  :
                  <i className="bi bi-eye-slash-fill"></i>
                }
                <span className="tw-font-semibold">Gifts</span>
              </button>
              <button type={"button"} onClick={() => setIsShowRequests(prev => !prev)}
                      className="tw-cursor-pointer tw-px-4 tw-pb-1 tw-pt-0.5 tw-text-blue-500 tw-bg-white tw-border tw-border-blue-500 tw-rounded-3xl tw-flex tw-items-center tw-gap-2">
                {isShowRequests ?
                  <i className="bi bi-eye-fill"></i>
                  :
                  <i className="bi bi-eye-slash-fill"></i>
                }
                <span className="tw-font-semibold">Requests</span>
              </button>
            </div>
          </div>


          <div>
            <div
              className={"tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-3"}
            >
              {["All", "My Gifts and Requests"].includes(activeTab) && (
                <>
                  {offers
                    .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
                    .filter((curr) => {
                      if (activeTab === "All") return true;
                      if (activeTab === "My Gifts and Requests") return curr.userId === store.userDetails.id;
                      return false;
                    })
                    .filter((curr) => {
                      if (!isShowRequests && curr.offerType === "request") return false;
                      return !(!isShowGifts && curr.offerType === "gift");
                    })
                    .filter((curr) => {
                      if (!search) return true;
                      return `${curr.title}${curr.user.firstName}${curr.user.lastName}`.toLowerCase().indexOf(search.toLowerCase()) >= 0
                    })
                    .filter(offer => !offer.isClosed)
                    .map((offer, index) => <Link
                      to={`/time-exchange/exchange?offerId=${offer.id}`}
                      className='p-0 card hover:!tw-shadow-lg border-1 tw-border-transparent hover:!tw-border-[#410db7] tw-transition-all'
                      key={index}>
                      <div className="card-header bg-transparent tw-flex tw-items-center tw-justify-between tw-gap-2">
                        <div className="tw-flex tw-items-center tw-gap-2">
                          <div>
                            <img
                              className='rounded-circle tw-w-[36px] tw-h-[36px] tw-object-cover shadow-sm tw-object-center'
                              src={offer.user.profilePic ? offer.user.profilePic : "/static/png/Male.png"}
                              alt=''/>
                          </div>

                          <Link
                            to={store.userDetails.id === offer.userId ? "/profile" : `/friends/${offer.userId}`}
                            className='mb-0 montserrat tw-font-medium'>
                            {offer.user.firstName} {offer.user.lastName}
                          </Link>
                        </div>
                        <div>
                          <div
                            className={"tw-px-5 tw-pb-1 tw-pt-0.5 tw-bg-blue-500 tw-text-sm tw-text-white tw-rounded-3xl"}>
                            {offer.offerType[0].toUpperCase()}{offer.offerType.slice(1)}
                          </div>
                        </div>
                      </div>

                      <div
                        className={"bg-primary tw-relative tw-w-full tw-h-[175px] p-3 d-flex justify-content-center text-center tw-leading-5 align-items-center tw-text-white tw-text-xl"}>
                        {offer.title}

                        <div
                          className={"tw-absolute tw-bottom-0 tw-left-0 m-2 px-2 tw-py-0.5 tw-bg-white tw-rounded tw-shadow"}>
                          <p className='mb-0 tw-text-sm tw-text-black'>
                            Total exchange
                            <span className="tw-font-semibold tw-text-[#e138bc]"> {offer.noOfHours} Hour</span>
                          </p>
                        </div>

                      </div>

                      {offer.isClosed && (
                        <div>
                          <div className="bg-danger tw-px-3 tw-pt-0.5 tw-pb-1 text-center text-white">
                            Closed
                          </div>
                        </div>
                      )}

                      {(offer.unpublishedAt && new Date(offer.unpublishedAt) < new Date()) && (
                        <div>
                          <div className="bg-secondary tw-px-3 tw-pt-0.5 tw-pb-1 text-center text-white">
                            Expired since{" "}
                            {
                              Intl.DateTimeFormat("En-SG", {
                                hour: "2-digit",
                                minute: "2-digit",
                              }).format(new Date(offer.unpublishedAt))
                            }
                          </div>
                        </div>
                      )}

                      <div className="card-body tw-whitespace-pre-wrap tw-break-words">
                        <PostText content={offer.description}/>
                      </div>
                      <div className="card-footer bg-transparent border-0">
                        <div className="text-end">
                          <button type={"button"} className={"btn ps-4 pe-3 py-1 border tw-font-semibold"}>
                            Learn More <i className="bi bi-chevron-right"/>
                          </button>
                        </div>
                        <PostText content={offer.category.map((v) => `#${v}`).join(" ")}/>
                      </div>
                    </Link>)}
                </>
              )}

              {["History", "Active"].includes(activeTab) && (
                <>
                  {
                    bids
                      .filter((curr) => {
                        if (!isShowRequests && curr.offer.offerType === "request") return false;
                        return !(!isShowGifts && curr.offer.offerType === "gift");
                      })
                      .filter(e => {
                        if (activeTab === 'Active') {
                          if (e.status === 'rejected') return false;
                          if (new Date() > new Date(e.endDate)) return false;
                        }
                        return true;
                      })
                      .filter((curr) => {
                          if (!search) return true;
                          return `${curr.offer.title}${curr.offer.user.firstName}${curr.offer.user.lastName}`
                            .toLowerCase()
                            .indexOf(search.toLowerCase()) >= 0;
                        }
                      )
                      .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
                      .map(bid => {
                        let author: User;
                        let requester: User;
                        if (bid.offer.offerType === "gift") {
                          author = bid.instructor;
                          requester = bid.user;
                        } else {
                          author = bid.user;
                          requester = bid.instructor;
                        }

                        return (
                          <Link to={`/time-exchange/exchange?offerId=${bid.offer.id}&bidId=${bid.id}`}
                                className='p-0 card hover:!tw-shadow-lg border-1 tw-border-transparent hover:!tw-border-[#410db7] tw-transition-all'
                                key={bid.id}>

                            <div
                              data-status={bid.status}
                              className={`
                                tw-px-3 tw-pt-0.5 tw-pb-1 tw-rounded-t tw-text-white tw-font-medium
                                data-[status='pending']:tw-bg-orange-500
                                data-[status='confirmed']:tw-bg-green-500
                                data-[status='rejected']:tw-bg-gray-500
                                data-[status='cancelled']:tw-bg-gray-500

                                tw-flex tw-items-center tw-justify-between
                              `}
                            >
                              <div>
                                {bid.status === "pending" && "Waiting Confirmation"}
                                {bid.status === "confirmed" && "Exchanged"}
                                {bid.status === "rejected" && "Rejected"}
                                {bid.status === "cancelled" && "Cancelled"}
                              </div>
                              <div>
                                <div className="bg-primary tw-px-3 tw-pb-0.5 tw-mt-0.5 tw-rounded-lg">
                                  {bid.offer.offerType === "gift" && "Offer"}
                                  {bid.offer.offerType === "request" && "Request"}
                                </div>
                              </div>
                            </div>


                            <div className="card-header bg-primary py-3">
                              <div className={"tw-flex tw-justify-between tw-items-center mb-2"}>
                                <p className={"tw-text-xl tw-text-semibold text-white"}>{bid.offer.title}</p>
                                <div
                                  className={"tw-bg-white tw-shadow tw-rounded tw-font-semibold tw-px-3 tw-pt-0.5 tw-pb-1 tw-text-[#e138bc]"}>{bid.offer.noOfHours} hour{bid.offer.noOfHours > 1 ? "s" : ""}</div>
                              </div>

                              <div className="pt-2">
                                <div className="bg-transparent d-flex align-items-center tw-gap-2">
                                  <div>
                                    <img
                                      className='rounded-circle tw-w-[36px] tw-h-[36px] tw-object-cover shadow-sm tw-object-center'
                                      src={author.profilePic ?? "/static/png/Male.png"}
                                      alt=''/>
                                  </div>

                                  <Link
                                    to={store.userDetails.id === author.id ? "/profile" : `/friends/${author.id}`}
                                    className='mb-0 montserrat tw-font-medium tw-text-sm tw-text-white'>
                                    {author?.firstName} {author?.lastName}
                                  </Link>
                                </div>
                              </div>
                            </div>


                            <div className="card-body pt-2">
                              <p
                                className={"tw-font-semibold mb-1"}>{bid.offer.offerType === "gift" ? "Requested" : "Responded"} by</p>
                              <div className="bg-transparent d-flex align-items-center tw-gap-2">
                                <div>
                                  <img
                                    className='rounded-circle tw-w-[36px] tw-h-[36px] tw-object-cover shadow-sm tw-object-center'
                                    src={requester.profilePic ? requester.profilePic : "/static/png/Male.png"}
                                    alt=''/>
                                </div>

                                <Link
                                  to={store.userDetails.id === requester.id ? "/profile" : `/friends/${requester.id}`}
                                  className='mb-0 montserrat tw-font-medium'>
                                  {requester.firstName} {requester.lastName}
                                </Link>
                              </div>
                            </div>
                          </Link>
                        )
                      })}
                </>
              )}

            </div>
          </div>
        </div>
      </div>
    </LoggedInLayout>
  )
}

export default Exchanges;
