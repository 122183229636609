import {createPostComments} from "../../../../utils/apiUrlsSetup";
import {Tooltip as ReactTooltip} from "react-tooltip";
import React, {useState} from "react";
import {useZStore} from "../../../../utils/zStore.ts";
import {growfield} from "../../../../utils/textarea_helper";
import {PuffLoader} from "react-spinners";

/**
 * @param {{
 *   comment: PostComment,
 *   afterSubmit: Function,
 * }} props
 * @constructor
 */
export default function ReplyBox(props) {
  const [comment, setComment] = useState("");
  const [imageData, saveImageData] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false)

  const store = useZStore()
  const userDetails = store.userDetails;

  const submitComment = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true)
    const formData = new FormData();
    formData.append("postId", props.comment.postId);
    formData.append("commentId", props.comment.id);
    formData.append("comment", comment);
    if (imageData) {
      formData.append("attachment", imageData)
    }

    await createPostComments(formData).then((e) => {
      props.afterSubmit()
    })
    setIsSubmitting(false)
  }

  const handlePaste = (e) => {
    const clipboardItems = e.clipboardData.items;

    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];

      // Check if the pasted item is an image
      if (item.type.indexOf('image') !== -1) {
        const file = item.getAsFile(); // Similar to e.target.files[0]
        saveImageData(file); // You can access the file object here
        const imageUrl = URL.createObjectURL(file); // Create an object URL to display the image
        setImageUrl(imageUrl);
        e.preventDefault(); // Prevent the default paste behavior
        break;
      }
    }
  };
  return (
    <div className="d-flex tw-gap-1 lg:tw-gap-2 tw-w-full">
      <div className="tw-w-[24px] lg:tw-w-[36px] tw-aspect-square tw-rounded-3xl flex-shrink-0">
        <img src={userDetails.profilePic} alt="Avatar" className={"tw-rounded-3xl"}/>
      </div>
      <div className={"tw-w-full border tw-rounded-xl tw-px-2.5 tw-py-1 tw-relative"}>

        {isSubmitting && (
          <>
            <div
              className={"tw-bg-white tw-left-0 tw-top-0 tw-absolute tw-w-full tw-h-full tw-rounded-xl tw-bg-opacity-80 tw-z-[900]"}/>
            <div className="tw-left-1/2 tw-top-1/2 translate-middle tw-absolute tw-z-[901]">
              <PuffLoader color={"#5458f7"}/>
            </div>
          </>
        )}

        <textarea
          id={`textareaComment-reply-${props.comment.id}`}
          className={"tw-w-full form-control-text tw-outline-none tw-mb-3"}
          rows={1}
          placeholder={"Reply..."}
          onPaste={handlePaste}
          onChangeCapture={(e) => {
            setComment(e.target.value);
            growfield(e.target)
          }}
        />
        <div className="tw-w-full tw-flex tw-items-end tw-justify-between">
          {
            imageUrl ? (
                <div className="d-flex flex-column">
                  <div className="tw-relative tw-w-[150px]">
                    <img src={imageUrl} alt=""
                         className={"tw-mb-1.5 tw-rounded-lg tw-w-full tw-transition-all tw-border tw-border-white hover:tw-border-gray-300"}/>

                    <div
                      className="tw-absolute start-100 top-0 tw-text-white translate-middle bg-secondary tw-cursor-pointer rounded-circle tw-w-[20px] tw-h-[20px] d-flex justify-content-center align-items-center"
                      onClick={() => {
                        setImageUrl("");
                        saveImageData(null);
                      }}>
                      <i className="bi bi-x"/>
                    </div>
                  </div>
                </div>
              )
              :
              (
                <div className={"d-flex align-items-center tw-h-[24px]"}>
                  <label
                    htmlFor={`add-comment-image-reply-${props.comment.id}`}
                    data-tooltip-id={`file-comment-image-reply-${props.comment.id}`}
                    data-tooltip-content={"Add Image"}
                    className={`tw-text-lg hover:tw-text-opacity-100 tw-text-opacity-70 tw-transition-all tw-text-[#410db7]`}>
                    <i className={"bi bi-image-fill d-flex align-items-end justify-content-end"}/>
                  </label>

                  <ReactTooltip
                    id={`file-comment-image-reply-${props.comment.id}`}
                    place={"top"}
                    effect={"solid"}
                  />

                  <div className={"d-none"}>
                    <input
                      type='file'
                      id={`add-comment-image-reply-${props.comment.id}`}
                      onChange={(e) => {
                        saveImageData(e.target.files[0]);
                        if (e.target.files[0]) {
                          const file = URL.createObjectURL(e.target.files[0]);
                          setImageUrl(file);
                        }
                        e.target.value = null;
                      }}
                      accept={"image/*"}
                    />
                  </div>
                </div>
              )
          }
          <div>
            <button
              className={`tw-text-[#5458f7] disabled:tw-text-opacity-60 disabled:tw-cursor-default tw-h-fit tw-text-lg tw-cursor-pointer`}
              onClick={() => submitComment()} disabled={!comment}>
              <i className="bi bi-send-fill before:tw-rotate-45 before:tw-content-['\f6b9']"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}