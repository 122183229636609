import {Modal, ModalBody, ModalHeader} from "reactstrap";
import { useZStore } from "../../utils/zStore.ts";

type ExperienceModal = {
  isOpen: boolean,
  onCommonModalButtonClick: Function,
  setExperienceType: (arg: "programme" | "community" | "event") => void,
}

const ExperienceModal = (props: ExperienceModal) => {
  const store = useZStore(state => state)

  return (
    <Modal isOpen={props.isOpen} toggle={() => props.onCommonModalButtonClick()} centered>
      <ModalHeader toggle={() => props.onCommonModalButtonClick()} className={"align-items-start border-0 pb-0"}>
        <h2 className="tw-text-xl tw-text-medium">Please choose an experience type</h2>
      </ModalHeader>
      <ModalBody>
        <div className={"tw-flex tw-gap-3"}>
          {[
            {
              type: "programme",
              allowed: store.userDetails.userType === 'wp'
            },
            {
              type: "community",
              allowed: true
            },
            {
              type: "event",
              allowed: true
            },
          ].filter(e => e.allowed)
            .map((experience) => {
              return (
                <button
                  key={experience.type}
                  className="tw-text-white tw-bg-[#5458f7] tw-rounded tw-w-full tw-pb-1 tw-px-3 tw-py-0.5 mt-2"
                  onClick={() => {
                    props.setExperienceType(experience.type as "programme" | "community" | "event")
                    props.onCommonModalButtonClick()
                  }}
                  type={"button"}
                >
                  {experience.type[0].toUpperCase() + experience.type.slice(1)}
                </button>
              )
            })}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ExperienceModal;
