import {useEffect, useState} from 'react';

import "../organization.css";
import CreateOrganizationModal from '../components/create-organization-modal'
import {
  changeOrgInvitationStatus,
  getOrgs,
  getOrgsForUser,
  getPostLike,
  joinOrg,
  leaveOrgStatus,
  respondToMembersOrg
} from '../../../utils/apiUrlsSetup';
import {useParams} from 'react-router';
import AddFriendModal from '../../staticPages/setting/component/addFriend-modal';
import OrganizationWeb from './organization-web';
import {useLocation, useNavigate} from 'react-router-dom';
import LikeProfileModal from './../../../component/modals/likeProfileModal/likeProfileModal';
import Swal from "sweetalert2";

const Organisation = () => {
  const [openModal, setOpenModal] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsBySearch, setOrganizationsBySearch] = useState([]);
  const [orgId, setOrdDetails] = useState([]);
  const [addfriendModal, setAddFriendModal] = useState(false);
  const [activeTab, setActiveTab] = useState("post");
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));

  const [showOrganisation, setShowOrganisation] = useState(false);

  const navigate = useNavigate();

  const {state} = useLocation();

  const [showUserLikedList, setShowUserLikedList] = useState(false);
  const [listOfLikeUsers, setListOfLikedUsers] = useState([]);

  const {id} = useParams();

  useEffect(() => {
    getAllOrgs(id);

    return () => {
      setOrdDetails([]);
    }
  }, [id]);

  useEffect(() => {
    if (state && state.memebersTabActive) {
      setActiveTab("member");
    } else if (state && state.postTabActive) {
      setActiveTab("post");
    }
  }, [state])

  const getAllOrgs = async (id) => {
    if (id) {
      const response = await getOrgs(id);
      if (response.status === 200) {
        setOrdDetails(response.data.data.organisationArray);
        const responseAll = await getOrgsForUser("accepted");
        if (responseAll.status === 200) {
          setOrganizations(responseAll.data.data.organisationArray.organisationArray.filter(v => v.status !== "deleted"));
          setShowOrganisation(true);
        }
      }
    } else {
      const response = await getOrgsForUser("accepted");
      if (response.status === 200) {
        setOrganizations(response.data.data.organisationArray.organisationArray.filter(v => v.status !== "deleted"))
      }
    }
  }

  const getOrgBySearch = async (orgSearch) => {
    const response = await getOrgs(null, orgSearch);
    if (response.status === 200) {
      let privateOrg = response.data.data.organisationArray ? response.data.data.organisationArray.filter(v => v.privacy === "private") : [];
      let findIsMember = privateOrg.map(v => v.members.filter(a => a.userId === userDetails.id && a.isAdmin).length ? [v] : []).filter(el => el.length);
      let filterPrivateOrg = response.data.data.organisationArray.filter(v => v.privacy !== "private");
      if (findIsMember.length) {
        setOrganizationsBySearch([...filterPrivateOrg, ...findIsMember[0]]);
      } else {
        setOrganizationsBySearch(filterPrivateOrg);
      }
    }
  };

  const joinGroupFunc = async () => {
    let res = await joinOrg(id);
    if (res.status === 200) {
      Swal.fire("Pending approval", "You will receive a notification email once your membership is approved.", "info")
      getAllOrgs(id);
    }
  }

  const naviagetToOrgById = (v) => {
    setOrdDetails([v]);
    setOrgSearch("");
    navigate(`/organisation/${v.id}`);
  }

  const confirmReq = async (memberId) => {
    const response = await changeOrgInvitationStatus(memberId, id, {status: "accepted"});
    if (response.status === 200) {
      getAllOrgs(id);
    }
  }

  const rejectReq = async (memberId) => {
    const response = await changeOrgInvitationStatus(memberId, id, {status: "rejected"});
    if (response.status === 200) {
      getAllOrgs(id);
    }
  }

  const deleteReq = async (memberId) => {
    const response = await changeOrgInvitationStatus(memberId, id, {status: "removed"});
    if (response.status === 200) {
      getAllOrgs(id);
    }
  }

  const cancelReq = async (leave) => {
    let obj = {};
    if (leave) {
      obj.status = "left"
    } else {
      obj.status = "cancel"
    }
    const response = await leaveOrgStatus(id, obj);
    if (response.status === 200) {
      getAllOrgs(id);
      if (obj.status === "left") {
        setOrdDetails([]);
        navigate('/new-organization')
      }
    }
  }

  const acceptReqByMemeber = async () => {
    let response = await respondToMembersOrg(id, {status: "accepted"});
    if (response.status === 200) {
      getAllOrgs(id);
    }
  }

  const rejectReqByMemeber = async () => {
    let response = await respondToMembersOrg(id, {status: "rejected"});
    if (response.status === 200) {
      getAllOrgs(id);
    }
  }

  const getListOfLikedUsers = async (post) => {
    let response = await getPostLike(`postId=${post.id}`)
    if (response.status === 200) {
      if (response.data.data.length) {
        setShowUserLikedList(true)
      }
      setListOfLikedUsers(response.data.data);
    }
  }

  return (
    <>
      <OrganizationWeb
        confirmReq={confirmReq}
        deleteReq={deleteReq}
        rejectReq={rejectReq}
        cancelReq={cancelReq}
        acceptReqByMemeber={acceptReqByMemeber}
        rejectReqByMemeber={rejectReqByMemeber}
        organizations={organizations}
        setOrdDetails={setOrdDetails}
        getAllOrgs={getAllOrgs}
        orgId={orgId}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setAddFriendModal={setAddFriendModal}
        setOpenModal={setOpenModal}
        joinGroupFunc={joinGroupFunc}
        organizationsBySearch={organizationsBySearch}
        naviagetToOrgById={naviagetToOrgById}
        id={id}
        showOrganisation={showOrganisation}
        setShowUserLikedList={(data) => getListOfLikedUsers(data)}
      />

      <AddFriendModal addfriendModal={addfriendModal} organisation orgId={id} closeModal={setAddFriendModal}
                      setIsShowInviteFrndModal={() => {
                        getAllOrgs(id);
                        setAddFriendModal(false);
                      }}/>


      <CreateOrganizationModal
        setOpenModal={(value) => {
          getAllOrgs();
          setOpenModal(value);
        }}
        openModal={openModal}
      />

      <LikeProfileModal isOpen={showUserLikedList} setShowUserLikedList={() => setShowUserLikedList(false)}
                        listOfLikeUsers={listOfLikeUsers}/>


    </>
  )
}

export default Organisation;