import React, {useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {ChangePasswordApi} from "../../../utils/apiUrlsSetup";

import '../../../css/loginAndSignup.css';

import Swal from "sweetalert2";
import {useParams} from "react-router";

function ResetPassword() {
  const [token, setToken] = useState(null)
  const {token: token_from_path} = useParams();

  const [passwordValidationError, setPasswordValidationError] = useState(null)
  const [confirmPasswordValidationError, setConfirmPasswordValidationError] = useState(null)
  const [matchpassword, setMatchPasswordValidationError] = useState()
  const [show, setshow] = useState(0);

  const [ispwd1, setIspwd1] = useState(false);

  const [ispwd2, setIspwd2] = useState(false);

  const navigate = useNavigate();


  const queryParams = new URLSearchParams(useLocation().search)

  if (queryParams && queryParams.get('token')) {
    if (!token) {
      setToken(queryParams.get('token'))
    }
  } else {
    if (!token) {
      setToken(token_from_path)
    }
  }

  const [pwd, setPwd] = useState({
    password: "",
    confirmPassword: "",
  })

  const onChangeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPwd({...pwd, [name]: value})


  }

  const handleSubmit = async () => {

    let isFail
    if (pwd.password === "") {
      isFail = true
      setPasswordValidationError("password is required")
    }
    if (pwd.confirmPassword === "") {
      isFail = true
      setshow(0)
      setConfirmPasswordValidationError("confirm password is required")
    }

    if (pwd.password !== pwd.confirmPassword) {
      isFail = true
      setshow(show + 1)
      setMatchPasswordValidationError("password should not match")
    }

    if (isFail) {

    } else {

      let objData = {}
      objData.password = pwd.password
      objData.token = token
      let responseData = await ChangePasswordApi(objData);

      if (responseData.statusCode === 200) {
        Swal.fire({
          title: "New password created!",
          text: "You can now access Kaleidoscope with your new password.",
          icon: "success",
        })
        navigate("/login")
      } else {
        Swal.fire({
          title: "Message",
          text: responseData.message
        })
      }
    }
  }

  return (
    <>
      <div className="row m-0 parent_row web_view">

        <div className="col-4 p-0 pe-4 ps-4 pt-5 left">
          <div className="row m-0">
            <div className="col-8 p-0 pe-5 d-flex align-items-center" onClick={() => {
              navigate('/')
            }} style={{cursor: 'pointer'}}>
              <img style={{width: '300px', height: '130px', marginLeft: '2% !important'}} src={"/static/svg/Logo.svg"}
                   alt=""/>
            </div>
          </div>
          <div className="row m-0 mt-4">
            <div className="col-2 p-0 pe-3 d-flex align-items-center justify-content-end">
            </div>
            <div className="col-4 p-0 pe-3 d-flex align-items-center justify-content-end">
              <p className="mb-0 sm_text">New to <br/>Well-being Champions Network?</p>
            </div>
            <div className="col-6 p-0 pe-5 d-flex align-items-center">
              <button className="signup_btn" onClick={() => {
                navigate('/signup')
              }}>Sign Up
              </button>
            </div>
          </div>
        </div>

        <div className="col-8 p-5 right">
          <div className="row m-0 mt-5">
            <div className="col-12 p-0 pt-5">
              <p className="mb-0 lg_text">Create new password</p>
              <p className="mb-0 mt-2 sm_blue_text">Please enter your new password.</p>
            </div>
          </div>

          <div className="row m-0 mt-5">
            <div className="col-12 p-0">
              <p className="mb-0 input_header">New Password</p>

              <div className="input-group input-group-class">
                <input
                  className="form-control input_filds mt-2"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  type={ispwd1 ? 'text' : 'password'}
                  name="password"
                  id="password"
                  onChange={onChangeHandler}
                />
                <span className="input-group-text pass_show_hide" id="basic-addon2"
                      onClick={() => setIspwd1(prevState => !prevState)}>{ispwd1 ? 'Hide' : 'Show'}</span>
              </div>
              {
                passwordValidationError ?
                  <span className='validation mt-2'>{passwordValidationError}</span> : null
              }

            </div>
          </div>

          <div className="row m-0 mt-4">
            <div className="col-12 p-0">
              <p className="mb-0 input_header">Confirm Password</p>

              <div className="input-group input-group-class">
                <input
                  className="form-control input_filds mt-2"
                  aria-label="Recipient's username"
                  aria-describedby="basic-addon2"
                  type={ispwd2 ? 'text' : 'password'}
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={onChangeHandler}
                />
                <span className="input-group-text pass_show_hide" id="basic-addon2"
                      onClick={() => setIspwd2(prevState => !prevState)}>{ispwd2 ? 'Hide' : 'Show'}</span>
              </div>
              {
                show === 0 && confirmPasswordValidationError ?
                  <span className='validation mt-2'>{confirmPasswordValidationError}</span> : null
              }
              {
                show === 1 && matchpassword ?
                  <span className='validation mt-2'>{matchpassword}</span> : null
              }


            </div>
          </div>

          <div className="row m-0 mt-5">
            <div className="col-9 p-0 login_btn_col d-flex align-items-center justify-content-end">
              <button className="reset_pass_btn" onClick={handleSubmit}>Reset Password</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword;