import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {addExpertiseHistory, addSurvey, signUp} from "../../../utils/apiUrlsSetup";


import '../../../css/loginAndSignup.css';
import {AUTOCAPITALIZED_FIELD, freeEmailDomains, instructorSurvey} from '../../../utils/constant';
import S3FileUpload from 'react-s3';
import {awsConfig} from '../../../env';

import _ from 'lodash'
import {ucwords} from "../../../utils/helpers";
import SignUpLeftSection from "./component/SignUpLeftSection";
import Logo from "../../../assets/wcn_logo.svg";
import SignUpForm from "./component/signUpForm";
import Swal from "sweetalert2";
import {useNavigate} from "react-router";

const SignUp = () => {
  const navigate = useNavigate();
  const [pwdValidationError, setPwdValidationError] = useState(null)

  const [btndisable, setBtnDisable] = useState(true);
  const [show, setShow] = useState(0);
  const [step, setStep] = useState(0);
  const [fileLoading, setFileLoading] = useState(false);

  const [ispwd, setIspwd] = useState(false);

  const [signupData, setsignupData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    setpassword: "",
    role: "user",
    userType: '',
    currentJobTitle: "",
    company: "",
    expertise: "Coach",
    expertiseDescription: "",
    mobile: "",
    certificates: [],
    degreeType: "",
    fieldStudy: "",
    school: "",
    startYear: "",
    endYear: "",
    companies: {},
    organisation: '',
    uen: '',
    jobTitle: '',
    previousOrgExperience: null,
    socialUrls: {},
    instructorSurveyData: {...instructorSurvey}
  });

  const [urlValidationMsg, setUrlValidationMsg] = useState("")
  const [isTextareaShown, setIsTextareaShown] = useState(false)
  useEffect(() => {
    const linkedInUrl = signupData.socialUrls.linkedInUrl

    if (linkedInUrl) {
      const pattern = /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/;
      if (pattern.test(linkedInUrl)) {
        setUrlValidationMsg('')
      } else {
        setUrlValidationMsg('Linkedin profile url is not valid')
      }
    }
  }, [signupData.instructorSurveyData, signupData.socialUrls.linkedInUrl])

  useEffect(() => {
    if (step === 2 && signupData.role === "user" &&
      (signupData.firstName &&
        signupData.lastName &&
        signupData.email &&
        signupData.jobTitle &&
        signupData.organisation &&
        // signupData.uen &&
        signupData.setpassword)) {
      setBtnDisable(false)
    } else if (step === 2 && signupData.role === "instructor" &&
      (signupData.firstName &&
        signupData.lastName &&
        signupData.email &&
        signupData.jobTitle &&
        signupData.organisation &&
        signupData.setpassword)) {
      setBtnDisable(false)
    } else {
      setBtnDisable(true)
    }
  }, [signupData, step])


  const handleMobileNumber = (value) => {
    setsignupData({...signupData, 'mobile': value})
  }

  const onChangeHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "company_1" || name === 'company_2' || name === 'company_3') {
      signupData.companies[name] = value
    }

    if (name === "email") {
      value = value.toLowerCase()
    }

    if (name === 'setpassword') {
      setPwdValidationError("");
    }

    if (name === 'userType') {
      if (value === 'wp') {
        signupData['role'] = "instructor"
      } else {
        signupData['role'] = "user"
      }
    }

    if (name === 'socialUrls') {
      let sUrls = {...signupData.socialUrls}
      sUrls['linkedInUrl'] = value
      signupData['socialUrls'] = sUrls
      setsignupData({...signupData})
      return
    }

    if (AUTOCAPITALIZED_FIELD.includes(name)) {
      value = ucwords(value)
    }

    setsignupData({
      ...signupData, [name]: value
    })

  }

  function ValidateEmail(email) {
    var mailformat = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/g;
    if (email.match(mailformat)) {
      const domain = email.split("@")[1].split(".")[0].toLowerCase()
      if (signupData.role === 'user' && freeEmailDomains.includes(domain)) {
        return "A business email address is required."
      } else {
        return "Email is valid"
      }
    } else {
      return "Email is invalid";
    }
  }

  function ValidatePwd(pwd) {
    var pwdFormat = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    if (pwd.match(pwdFormat)) {
      return "Password is valid"
    } else {
      return "Password format is invalid"
    }
  }

  function ValidateUen(uen) {
    if (signupData.uen) {
      var uenFormat = /^[a-zA-Z0-9]{8,9}[a-zA-Z]$/;
      if (uen.match(uenFormat)) {
        return "UEN is valid"
      } else {
        return "Please enter a valid UEN."
      }
    } else {
      return "UEN is valid"
    }
  }

  const handleInstructorSurveyData = (event) => {
    let instructorSurveyData = _.cloneDeep(signupData.instructorSurveyData);
    instructorSurveyData[event.target.name]['answer'] = event.target.value
    signupData['instructorSurveyData'] = instructorSurveyData
    setsignupData({...signupData})
  }

  const handleCertificateAttachment = async (event) => {
    setFileLoading(true)
    for (let i = 0; i < event.target.files.length; i++) {
      const response = await S3FileUpload.uploadFile(event.target.files[i], awsConfig)
      if (response && response.location) {
        const certificates = [...signupData.certificates]
        certificates.push(response.location)
        setsignupData({...signupData, 'certificates': certificates})
      }
    }

    setFileLoading(false)

  }

  const handleSubmit = async () => {
    let objData = {}
    objData.firstName = signupData.firstName
    objData.lastName = signupData.lastName
    objData.email = signupData.email
    objData.role = signupData.role
    objData.password = signupData.setpassword
    objData.deviceToken = "123"
    objData.deviceType = "web"
    objData.organisation = signupData.organisation
    if (signupData.uen) {
      objData.uen = signupData.uen
    }
    objData.job_title = signupData.jobTitle
    if (signupData.mobile) {
      objData.mobile = signupData.mobile
    }
    objData.userType = signupData.userType
    if (objData.role === 'instructor') {
      objData.certificates = signupData.certificates
      objData.socialUrls = signupData.socialUrls
    } else {
      objData.prev_org_experience = signupData.previousOrgExperience
    }

    let responseData = await signUp(objData);
    if (responseData && responseData.statusCode === 200) {
      localStorage.setItem("accessToken", responseData.data.accessToken);
      if (objData.role === 'instructor') {
        objData = {}
        objData['expertise'] = signupData.expertise
        objData['communityArray'] = []
        if (signupData.expertiseDescription) {
          objData['expertiseDescription'] = signupData.expertiseDescription
        }
        responseData = await addExpertiseHistory(objData);
        if (responseData && responseData.statusCode === 200) {
          objData = {}
          objData['surveyData'] = signupData.instructorSurveyData
          responseData = await addSurvey(objData);
          localStorage.removeItem("accessToken");
          if (responseData && responseData.statusCode === 200) {
            navigate("/")
            Swal.fire("Congratulations!", "Your application has been received. We will review your submission and get back to you soon.", "success")
          }
          return
        }
      }
      localStorage.removeItem("accessToken");
      navigate("/")
      if (objData.role === "user") {
        Swal.fire("Congratulations!", "Your account has been created. Please check your email to verify your account.", "success")
      } else {
        Swal.fire("Congratulations!", "Your application has been received. We will review your submission and get back to you soon.", "success")
      }
    }
  }


  const setRole = async (val) => {
    setsignupData({...signupData, 'role': val})
  }

  let renderedView;

  const question1 = {...signupData.instructorSurveyData['question-1']}
  const surveyData = {...signupData.instructorSurveyData}

  const QUESTION_FLOW = {
    0: [
      {
        condition: true,
        item: {
          title: "I am applying as a...",
          questions: [
            {
              condition: true,
              q: "",
              a: {
                type: "radio",
                name: "userType",
                selected: signupData.userType,
                options: [
                  {
                    label: "Well-being CHAMPION",
                    value: 'wc',
                    onChange: onChangeHandler,
                    tooltip: "Select this if you lead Well-being initiatives and it is PART OF your organisational role (e.g. HR, EHS)."
                  },
                  {
                    label: "Well-being PEER SUPPORTER",
                    value: 'ps',
                    onChange: onChangeHandler,
                    tooltip: "Select this if you support Well-being initiatives but it is NOT PART OF your organisational role. (e.g. Customer Service, Production Supervisor).",
                  },
                  {
                    label: "Well-being PROVIDER",
                    value: 'wp',
                    onChange: onChangeHandler,
                    tooltip: "Select this if you are certified Counsellor, Therapist, Coach, Trainer or Mentor.",
                  },
                ]
              }
            }
          ],
          buttons: [
            {
              label: "Next",
              onClick: () => setStep(1),
              disabled: !signupData.userType,
              className: !signupData.userType ? 'deactive-btn' : 'active_btn'
            }
          ]
        }
      }
    ],
    1: [
      {
        condition: signupData.userType === "wp",
        item: {
          title: "I am applying as a certified...",
          questions: [
            {
              condition: true,
              q: "",
              a: {
                type: "select",
                name: "expertise",
                onChange: onChangeHandler,
                options: [
                  {
                    label: "Coach",
                    value: 'Coach',
                  },
                  {
                    label: "Counsellor",
                    value: 'Counsellor',
                  },
                  {
                    label: "Therapist",
                    value: 'Therapist',
                  },
                  {
                    label: "Trainer",
                    value: 'Trainer',
                  },
                  {
                    label: "Mentor",
                    value: 'Mentor',
                  },
                  {
                    label: "Other",
                    value: 'Other',
                  },
                ]
              }
            },
            {
              condition: signupData.expertise === "Other",
              q: "Please specify your expertise",
              a: {
                type: "shorttext",
                name: "expertiseDescription",
                onChange: onChangeHandler,
              }
            }
          ],
          buttons: [
            {
              label: "Back",
              className: "active_btn",
              onClick: () => setStep(0),
            },
            {
              label: "Next",
              onClick: () => setStep(2),
              disabled: !signupData.expertise,
              className: !signupData.expertise ? 'deactive-btn' : 'active_btn'
            }
          ]
        }
      },
      {
        condition: signupData.userType === "wc",
        item: {
          title: "Have you been formally appointed as your organisational Well-being Champion or Ambassador?",
          questions: [
            {
              condition: true,
              q: "",
              a: {
                type: "radio",
                name: "previousOrgExperience",
                selected: signupData.previousOrgExperience,
                options: [
                  {
                    label: "Yes",
                    value: "true",
                    onChange: onChangeHandler,
                  },
                  {
                    label: "No",
                    value: "false",
                    onChange: onChangeHandler,
                  },
                ]
              }
            }
          ],
          buttons: [
            {
              label: "Back",
              className: "active_btn",
              onClick: () => setStep(0),
            },
            {
              label: "Next",
              onClick: () => setStep(2),
              disabled: signupData.previousOrgExperience === null,
              className: signupData.previousOrgExperience === null ? 'deactive-btn' : 'active_btn'
            }
          ]
        }
      },
      {
        condition: signupData.userType === "ps",
        item: {
          title: "Have you received training as a Peer Supporter?",
          questions: [
            {
              condition: true,
              q: "",
              a: {
                type: "radio",
                name: "previousOrgExperience",
                selected: signupData.previousOrgExperience,
                options: [
                  {
                    label: "Yes",
                    value: "true",
                    onChange: onChangeHandler,
                  },
                  {
                    label: "No",
                    value: "false",
                    onChange: onChangeHandler,
                  },
                ]
              }
            }
          ],
          buttons: [
            {
              label: "Back",
              className: "active_btn",
              onClick: () => setStep(0),
            },
            {
              label: "Next",
              onClick: () => setStep(2),
              disabled: signupData.previousOrgExperience === null,
              className: signupData.previousOrgExperience === null ? 'deactive-btn' : 'active_btn'
            }
          ]
        }
      }
    ],
    3: [
      {
        condition: true,
        item: {
          title: "Please provide details about your professional qualification/s.",
          questions: [
            {
              condition: true,
              q: "Your LinkedIn Profile URL",
              a: {
                type: "shorttext",
                name: "socialUrls",
                onChange: onChangeHandler,
                error: urlValidationMsg
              }
            },
            {
              condition: true,
              q: instructorSurvey['question-1'].description,
              a: {
                type: "radio",
                name: "question-1",
                selected: signupData.instructorSurveyData['question-1'].answer,
                options: [
                  {
                    label: "Programmes",
                    value: 'Programmes',
                    onChange: (e) => {
                      handleInstructorSurveyData(e)
                      setIsTextareaShown(false)
                    },
                  },
                  {
                    label: "Counseling",
                    value: 'Counseling',
                    onChange: (e) => {
                      handleInstructorSurveyData(e)
                      setIsTextareaShown(false)
                    },
                  },
                  {
                    label: "Therapy",
                    value: 'Therapy',
                    onChange: (e) => {
                      handleInstructorSurveyData(e)
                      setIsTextareaShown(false)
                    },
                  },
                  {
                    label: "Feed Content",
                    value: 'Feed Content',
                    onChange: (e) => {
                      handleInstructorSurveyData(e)
                      setIsTextareaShown(false)
                    },
                  },
                  {
                    label: "Other",
                    value: 'Other',
                    onChange: (e) => {
                      handleInstructorSurveyData(e)
                      setIsTextareaShown(e.target.checked)
                    },
                  },
                ]
              }
            },
            {
              condition: isTextareaShown,
              q: "Please describe more...",
              a: {
                type: "shorttext",
                name: "question-1",
                onChange: handleInstructorSurveyData,
              }
            },
          ],
          buttons: [
            {
              label: "Back",
              className: "active_btn",
              onClick: () => setStep(2),
            },
            {
              label: "Next",
              onClick: () => setStep(4),
              disabled: !question1['answer'] || urlValidationMsg,
              className: (!question1['answer'] || urlValidationMsg) ? 'deactive-btn' : 'active_btn'
            }
          ]
        }
      }
    ],
    4: [
      {
        condition: true,
        item: {
          title: "Your Professional Certification/s",
          questions: [
            {
              condition: true,
              q: instructorSurvey['question-2'].description,
              a: {
                type: "radio",
                name: "question-2",
                selected: signupData.instructorSurveyData['question-2'].answer,
                options: [
                  {
                    label: "Yes",
                    value: 'true',
                    onChange: handleInstructorSurveyData
                  },
                  {
                    label: "No",
                    value: 'false',
                    onChange: handleInstructorSurveyData
                  },
                ]
              }
            },
            {
              condition: true,
              q: instructorSurvey['question-3'].description,
              a: {
                type: "radio",
                name: "question-3",
                selected: signupData.instructorSurveyData['question-3'].answer,
                options: [
                  {
                    label: "Yes",
                    value: 'true',
                    onChange: handleInstructorSurveyData
                  },
                  {
                    label: "No",
                    value: 'false',
                    onChange: handleInstructorSurveyData
                  },
                ]
              }
            },
            {
              condition: true,
              q: "Please attach the relevant certifications or documentations that support your application as a Well-being Provider.",
              a: {
                type: "file",
                name: "instructorCertificates",
                multiple: true,
                onChange: handleCertificateAttachment
              }
            },
          ],
          buttons: [
            {
              label: "Back",
              onClick: () => setStep(3),
              className: "active_btn"
            },
            {
              label: "Submit",
              onClick: handleSubmit,
              disabled: (fileLoading || !surveyData['question-2']['answer'] || !surveyData['question-3']['answer'] || signupData.certificates.length === 0),
              className: (fileLoading || !surveyData['question-2']['answer'] || !surveyData['question-3']['answer'] || signupData.certificates.length === 0) ? 'deactive-btn' : 'active_btn'
            }
          ]
        }
      }
    ],
  }

  switch (step) {
    case 2:
      renderedView = (
        <>
          <SignUpForm
            onChangeHandler={onChangeHandler}
            pwdValidationError={pwdValidationError}
            setIspwd={setIspwd}
            ispwd={ispwd}
            signupData={signupData}
            setRole={setRole}
            btndisable={btndisable}
            setShow={setShow}
            show={show}
            handleSubmit={handleSubmit}
            setStep={setStep}
            step={step}
            handleMobileNumber={handleMobileNumber}
            ValidateEmail={ValidateEmail}
            ValidatePwd={ValidatePwd}
            ValidateUen={ValidateUen}
          />
        </>
      )
      break
    default:
      const targetItem = QUESTION_FLOW[step].find(e => e.condition).item;
      renderedView = (
        <div className={"d-flex flex-column gap-4"}>
          <div className={"text-center"}>
            <p className="tw-text-3xl tw-text-[#54bfed] tw-font-semibold">{targetItem.title}</p>
            {targetItem.dangerText && (
              <p className={"text-danger"}>{targetItem.dangerText}</p>
            )}
          </div>

          <hr className="m-0 p-0"/>

          {targetItem.questions.map((question, i) => {
            return (
              <div key={i} className={question.condition ? "" : "d-none"}>
                {question.q && (
                  <div className={"d-flex flex-column justify-content-center align-items-center"}>
                    <p className="mb-2 tw-w-[600px] tw-max-w-[100%]">{question.q}</p>
                  </div>
                )}
                {question.a.type === "radio" && (
                  <div className={"d-flex flex-column gap-3 justify-content-center align-items-center"}>
                    {question.a.options.map((option, idx) => {
                      return (
                        <label
                          className={`card card-body shadow-sm tw-rounded-3xl tw-w-[600px] tw-max-w-[100%] tw-cursor-pointer data-[selected='true']:!tw-bg-[#eeeaff]`}
                          data-selected={question.a.selected === option.value}
                          htmlFor={`radio-${idx}=q${i}s${step}`}
                          // ensure each radio button has a unique key
                          key={(100 * idx) + (10 * i) + step}>
                          <div className="d-flex align-items-center gap-2 fw-semibold">
                            <div
                              data-selected={question.a.selected === option.value}
                              className="tw-bg-[#eeeaff] rounded-circle tw-w-[30px] tw-pb-0.5 flex-shrink-0 tw-h-[30px] data-[selected='true']:!tw-bg-[#5458f7] data-[selected='true']:!tw-text-white d-flex justify-content-center align-items-center">
                              {String.fromCharCode(65 + idx)}
                            </div>
                            {option.label}
                          </div>
                          <div
                            className={`align-items-center gap-2 text-start tw-text-sm ${option.tooltip ? "d-flex" : "d-none"}`}>
                            <div className="invisible tw-w-[30px] tw-h-[30px] flex-shrink-0"/>
                            {option.tooltip}
                          </div>

                          <input className="d-none" type="radio"
                                 name={question.a.name}
                                 value={option.value}
                                 onChange={option.onChange}
                                 id={`radio-${idx}=q${i}s${step}`}/>
                        </label>
                      )
                    })}
                  </div>
                )}

                {question.a.type === "select" && (
                  <div className={"d-flex justify-content-center align-items-center"}>
                    <select
                      className="input_filds signup_inputes selectInput tw-w-[600px] tw-max-w-[100%]"
                      name={question.a.name}
                      id={question.a.name}
                      onChange={question.a.onChange}
                    >
                      {question.a.options.map((option, idx) => {
                        return (
                          <option key={idx} value={option.value}>{option.label}</option>
                        )
                      })}
                    </select>
                  </div>

                )}

                {question.a.type === "shorttext" && (
                  <div className={"d-flex justify-content-center align-items-center flex-column"}>
                    <input
                      className="input_filds signup_inputes tw-w-[600px] tw-max-w-[100%]"
                      type="text"
                      id={`shorttext-${question.a.name}`}
                      name={question.a.name}
                      onChange={question.a.onChange}
                    />
                    {question.a.error &&
                      <span className="validation mt-2">{question.a.error}</span>
                    }
                  </div>
                )}

                {question.a.type === "file" && (
                  <div className={"d-flex justify-content-center align-items-center flex-column"}>
                    <input
                      className="form-control tw-w-[600px] tw-max-w-[100%]"
                      type="file"
                      id={`file-${question.a.name}`}
                      name={question.a.name}
                      multiple={question.a.multiple}
                      required
                      accept=".pdf, image/png, image/jpeg, image/jpg"
                      onChange={question.a.onChange}
                    />
                  </div>
                )}
              </div>
            )
          })}

          <div className={"d-flex gap-3 justify-content-end align-items-center"}>
            {targetItem.buttons.map((button, i) => {
              return (
                <button
                  key={i}
                  className={`next_btn ${button.className}`}
                  disabled={button.disabled}
                  onClick={button.onClick}>
                  {button.label}
                </button>
              )
            })}
          </div>
        </div>
      )
      break;
  }

  return (
    <div className="min-vh-100 bg-logged-in">
      {/* Left Side */}
      <div className={"d-lg-block d-none"}>
        <SignUpLeftSection
          setShow={setShow}
          show={show}
          step={step}
          role={signupData.role}
        />
      </div>

      {/* Top Side */}
      <div className={"d-lg-none d-block"}>
        <div className={"position-relative"}>
          <nav className={"shadow-sm montserrat position-relative"} style={{
            background: "linear-gradient(119.23deg, #e9dfff 4.63%, #edfaff 53.65%)",
            zIndex: 1001
          }}>
            <div className="container py-2">
              <div className="d-flex justify-content-between align-items-center">
                <img src={Logo} alt='logo' width={130 * .6} height={59 * .6}/>

                <Link to={"/notification"}>
                  <button type={"button"}>
                    Login
                  </button>
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className={"lg:tw-ms-[250px] px-xl-5 px-lg-4 px-md-3 min-vh-100"}>
        <div className="container py-lg-4 py-3">
          {renderedView}
        </div>
      </div>
    </div>
  )
}

export default SignUp;
